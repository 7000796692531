// Models
import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";

export class Broker implements Exportable {


  id:                  number = 0;
  version:             number | undefined;
  name:                string | undefined;
  brokerType:          number | undefined;
  lastPingAt:          Date | undefined;

  address:             string | undefined;
  port:                string | undefined;
  user:                string | undefined;
  password:            string | undefined;
  keystoreKey:         string | undefined;
  keystoreKeyPassword: string | undefined;
  keystoreTrust:       string | undefined;

  constructor(){}

}

export class BrokerFilter implements QueryFilter {

  name:      string | undefined;

}


export class BrokerExporter implements Exporter  {

  name;
  brokerType;
  address;
  port;


  constructor() {

    this.name= {
      nameToShow: 'Nombre'
    };
    this.brokerType = {
      nameToShow: 'Tipo'
    };
    this.address = {
      nameToShow: 'URL'
    };
    this.port = {
      nameToShow: 'Puerto'
    };

  }
}
